import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout/Layout';
import Contacts from '~/components/Contacts/Contacts';

const ContactPage = ({ data }) => {
  const { contacts, social, pages, title, calendly } = data.site.siteMetadata;
  const meta = pages.contact;

  return (
    <Layout meta={meta} socials={social} title={title} calendly={calendly}>
      <div className="page contact">
        <h1 className="page-title">Contact Us</h1>
        <Contacts contacts={contacts} />
      </div>
    </Layout>
  );
};

ContactPage.propTypes = {
  path: PropTypes.string,
  data: PropTypes.object
};

export default ContactPage;

// eslint-disable-next-line one-var
export const pageQuery = graphql`
  query ContactPage {
    site {
      siteMetadata {
        title
        calendly {
          domain
          kubeflow {
            url
            googleAnalytics {
              category
              action
              label
            }
          }
        }
        contacts {
          department
          email
        }
        pages {
          contact {
            title
            description
            keywords
            googleSiteVerification
          }
        }
        social {
          twitter
          github
          linkedin
          email
          feed
        }
      }
    }
  }
`;
