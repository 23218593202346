import React from 'react';
import PropTypes from 'prop-types';

import './Contacts.css';

const Contacts = ({ contacts }) => (
  <div className="contacts-list">
    {contacts.map((contact) => (
      <div className="contacts-item" key={contact.email}>
        <h2 className="contacts-title">{contact.department}</h2>
        <a href={`mailto:${contact.email}`}>{contact.email}</a>
      </div>
    ))}
  </div>
);

Contacts.propTypes = {
  contacts: PropTypes.array
};

export default Contacts;
